import 'jquery-validation'
import 'jquery-validation/dist/additional-methods'

export default formSelector => {
  const forms = $(formSelector)

  const getWordCount = (wordString) => {
    let words = wordString.split(' ')
    words = words.filter((words) => {
      return words.length > 0
    }).length
    return words
  }

  $.validator.addMethod(
      'userMail',
      function (value) {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,12}$/.test(value)
      },
      'Vul alstublieft een geldig e-mailadres in'
  )

  $.validator.addMethod(
      'wordCount',
      function(value, element, params) {
        let count = getWordCount(value)
        if(count >= params[0]) {
          return true
        }
      },
      $.validator.format('A minimum of {0} words is required here.')
  )

  $.validator.addMethod(
      'fullName',
      function(value) {
        let count = getWordCount(value)
        if(count >= 2) {
          return true
        }
      },
      $.validator.format('Vul je volledige naam in.')
  )

  $.validator.addMethod(
      'companyMail',
      function (value) {
        let domainMatch = value.match(/@(.*)/)

        if (domainMatch === null) {
          return true
        }

        let domain = domainMatch[1]

        return window.emailblacklist === undefined || window.emailblacklist.indexOf(domain) === -1
      },
      'Vul een zakelijk e-mailadres in'
  )

  $.validator.addClassRules({
    'js-validate-mail': {
      required: true,
      minlength: 3,
      userMail: true,
      companyMail: true
    }
  })

  $.validator.addClassRules({
    'js-validate-fullname': {
      required: true,
      fullName: true
    }
  })


  forms.each(function() {
    $(this).validate({
      highlight: function(element) {
        if (!$(element).hasClass('js-opt-group')) {
          $(element)
            .parent()
            .addClass('field-error')
        }
      },
      unhighlight: function(element) {
        if (!$(element).hasClass('js-opt-group')) {
          $(element)
            .parent()
            .removeClass('field-error')
        }
      },
      errorPlacement: function(error, element) {
        if (!$(element).hasClass('js-opt-group')) {
          error.insertAfter(element)
        } else {
          const currField = element.closest('.field')
          const currGroup = element.closest('.js-opt-group-container')
          if (!currField.hasClass('field-error')) {
            currField.addClass('field-error')
            currGroup.append(error)
          }
        }
      }
    })

    $(this).find('.js-opt-group').each(function() {
      $(this).rules('add', {
        require_from_group: [1, '.' + $(this).data('group')]
      })
    })
  })

  $('#orgform').validate({
    highlight: function(element) {
      if (!$(element).hasClass('js-opt-group')) {
        $(element)
          .parent()
          .addClass('field-error')
      }
    },
    unhighlight: function(element) {
      if (!$(element).hasClass('js-opt-group')) {
        $(element)
          .parent()
          .removeClass('field-error')
      }
    },
    errorPlacement: function(error, element) {
      if (!$(element).hasClass('js-opt-group')) {
        error.insertAfter(element)
      } else {
        const currGroup = element.closest('.js-opt-group-container')
        if (!currGroup.hasClass('field-error')) {
          currGroup.append(error)
          currGroup.addClass('field-error')
        }
      }
    }
  })

  $('#orgform .js-opt-group').each(function() {
    $(this).rules('add', {
      require_from_group: [1, '.' + $(this).data('group')]
    })
  })

  $.extend( $.validator.messages, {
    required: 'Dit is een verplicht veld.',
    remote: 'Controleer dit veld.',
    email: 'Vul hier een geldig e-mailadres in.',
    companyMail: 'Vul een zakelijk e-mailadres in',
    url: 'Vul hier een geldige URL in.',
    date: 'Vul hier een geldige datum in.',
    dateISO: 'Vul hier een geldige datum in (ISO-formaat).',
    number: 'Vul hier een geldig getal in.',
    digits: 'Vul hier alleen getallen in.',
    creditcard: 'Vul hier een geldig creditcardnummer in.',
    equalTo: 'Vul hier dezelfde waarde in.',
    extension: 'Vul hier een waarde in met een geldige extensie.',
    maxlength: $.validator.format( 'Vul hier maximaal {0} tekens in.' ),
    minlength: $.validator.format( 'Vul hier minimaal {0} tekens in.' ),
    rangelength: $.validator.format( 'Vul hier een waarde in van minimaal {0} en maximaal {1} tekens.' ),
    range: $.validator.format( 'Vul hier een waarde in van minimaal {0} en maximaal {1}.' ),
    max: $.validator.format( 'Vul hier een waarde in kleiner dan of gelijk aan {0}.' ),
    min: $.validator.format( 'Vul hier een waarde in groter dan of gelijk aan {0}.' ),
    step: $.validator.format( 'Vul hier een veelvoud van {0} in.' ),

    // For validations in additional-methods.js
    iban: 'Vul hier een geldig IBAN in.',
    dateNL: 'Vul hier een geldige datum in.',
    phoneNL: 'Vul hier een geldig Nederlands telefoonnummer in.',
    mobileNL: 'Vul hier een geldig Nederlands mobiel telefoonnummer in.',
    postalcodeNL: 'Vul hier een geldige postcode in.',
    bankaccountNL: 'Vul hier een geldig bankrekeningnummer in.',
    giroaccountNL: 'Vul hier een geldig gironummer in.',
    bankorgiroaccountNL: 'Vul hier een geldig bank- of gironummer in.',
    require_from_group: 'Vul minimaal {0} van deze velden in.',
    wordCount: $.validator.format( 'Hier zijn {0} woorden vereist.' ),
    fullName: $.validator.format( 'Vul je volledige naam in.' )
  } )
}
